import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import rightArrow from "../../assets/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsByOrderId, resendMail, uploadVisaDocument } from "../../features/order/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  // console.log('OrderDetails: ', OrderDetails?.email)
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(OrderDetails?.email);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);
  const [mailServiceProvider, setMailServiceProvider] = useState('AWS');

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | Vietnam E-visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.email &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.email);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.email,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const { values, errors, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      data.append("mailServiceProvider", mailServiceProvider);
      dispatch(uploadVisaDocument(data)).unwrap().then((res) => {
        resetForm();
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
        }
      });
    },
  });

  const handleResendMail = (e, serviceProvider) => {
    e.preventDefault();
    setMailServiceProvider(serviceProvider);
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
      mailServiceProvider: serviceProvider
    };
    dispatch(resendMail(data)).unwrap().then((res) => {
      if (res.status === 1) {
        toast.success(`${res.message}`, {
          className: "toast-message",
        });
        dispatch(getOrderDetailsByOrderId(orderId));
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          {OrderDetails?.uploadDoc?.length ? (
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                    }docs/${OrderDetails?.order_id}_${item?.uploadDoc == null
                                      ? OrderDetails?.uploadDoc[0]?.file1
                                      : item?.uploadDoc.file1
                                    }`
                                    : null
                                }
                                className="blue-btn"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                              </a>
                            </td>
                          ) : (
                            ""
                          )}
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "AWS");
                      }}
                    >
                      Resend Mail(AWS){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>

                    <button
                      type="button"
                      className="blue-btn"
                      id="download_btn_sendgrid"
                      disabled={isLoading}
                      onClick={(e) => {
                        handleResendMail(e, "Sendgrid");
                      }}
                    >
                      Resend Mail(Sendgrid){" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                    {/* <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button> */}
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <tr>
                        <td>
                          <label className="file-upload">
                            <input
                              type="file"
                              className=""
                              id="fileUpload"
                              name="fileUpload"
                              accept=".pdf,image/*"
                              onChange={(e) =>
                                setFieldValue(
                                  "fileUpload",
                                  e.currentTarget.files[0]
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <span>Choose File</span>
                          </label>
                          <span className="slcted-file"></span>
                          <p>{errors.fileUpload}</p>
                        </td>
                      </tr>
                    </tr>
                    {OrderDetails?.uploadDoc?.length ? (
                      <td colSpan="2">
                        <a
                          href={
                            OrderDetails?.length !== 0
                              ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.uploadDoc[0]?.file1}`
                              : null
                          }
                          className="blue-btn"
                          download
                          rel="noreferrer"
                        >
                          Download File{" "}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('AWS')}
                >
                  Submit (AWS){" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn_sendgrid"
                  disabled={isLoading}
                  onClick={() => setMailServiceProvider('Sendgrid')}
                >
                  Submit (SendGrid){" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>PARTICULARS OF APPLICANT</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>

                  {OrderDetails?.applicant_photo_upload && (
                    <tr>
                      <td>Upload Applicant's Photo</td>
                      <td>
                        <a
                          href={`https://malaysia-evisa.online/uploads/passport/${OrderDetails?.applicant_photo_upload}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.passport_upload && (
                    <tr>
                      <td>Upload Passport Photo</td>
                      <td>
                        <a
                          href={`https://malaysia-evisa.online/uploads/passport/${OrderDetails?.passport_upload}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.surname && (
                    <tr>
                      <td>Surname</td>
                      <td>{OrderDetails?.surname}</td>
                    </tr>
                  )}
                  {OrderDetails?.given_name && (
                    <tr>
                      <td>Given Name</td>
                      <td>{OrderDetails?.given_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.dob && (
                    <tr>
                      <td>Date of Birth</td>
                      <td>{OrderDetails?.dob}</td>
                    </tr>
                  )}
                  {OrderDetails?.nationality && (
                    <tr>
                      <td>Nationality</td>
                      <td>{OrderDetails?.nationality}</td>
                    </tr>
                  )}
                  {OrderDetails?.sex && (
                    <tr>
                      <td>Sex</td>
                      <td>{OrderDetails?.sex}</td>
                    </tr>
                  )}



                  {OrderDetails?.country_code && (
                    <tr>
                      <td>Phone Code</td>
                      <td>{"+" + OrderDetails?.country_code}</td>
                    </tr>
                  )}
                  {OrderDetails?.phone && (
                    <tr>
                      <td>Phone Number</td>
                      <td>{OrderDetails?.phone}</td>
                    </tr>
                  )}


                  {OrderDetails?.email && (
                    <tr>
                      <td>Email</td>
                      <td>{OrderDetails?.email}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>VISA DETAILS</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.app_purpose_stay && (
                    <tr>
                      <td>Visa Type</td>
                      <td>{OrderDetails?.app_purpose_stay}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>PARTICULARS OF PASSPORT/TRAVEL DOCUMENT</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.app_doc_type && (
                    <tr>
                      <td>Travel Document</td>
                      <td>{OrderDetails?.app_doc_type}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_doc_number && (
                    <tr>
                      <td>Document Number</td>
                      <td>{OrderDetails?.app_doc_number}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_doc_country_issued && (
                    <tr>
                      <td>Place/Country of Issued</td>
                      <td>{OrderDetails?.app_doc_country_issued}</td>
                    </tr>
                  )}
                  {OrderDetails?.issuance_date && (
                    <tr>
                      <td>Passport Issuance Date</td>
                      <td>{OrderDetails?.issuance_date}</td>
                    </tr>
                  )}
                  {OrderDetails?.passport_expiry_date && (
                    <tr>
                      <td>Passport Expiry Date</td>
                      <td>{OrderDetails?.passport_expiry_date}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Address In Malaysia</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.app_mys_address1 && (
                    <tr>
                      <td>Address 1</td>
                      <td>{OrderDetails?.app_mys_address1}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_mys_address2 && (
                    <tr>
                      <td>Address 2</td>
                      <td>{OrderDetails?.app_mys_address2}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_mys_postcode && (
                    <tr>
                      <td>Postcode</td>
                      <td>{OrderDetails?.app_mys_postcode}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_mys_city && (
                    <tr>
                      <td>City</td>
                      <td>{OrderDetails?.app_mys_city}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Current Local Address</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.app_address1 && (
                    <tr>
                      <td>Address 1</td>
                      <td>{OrderDetails?.app_address1}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_address2 && (
                    <tr>
                      <td>Address 2</td>
                      <td>{OrderDetails?.app_address2}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_postcode && (
                    <tr>
                      <td>Postcode</td>
                      <td>{OrderDetails?.app_postcode}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_city && (
                    <tr>
                      <td>City</td>
                      <td>{OrderDetails?.app_city}</td>
                    </tr>
                  )}
                  {OrderDetails?.country && (
                    <tr>
                      <td>Country</td>
                      <td>{OrderDetails?.country}</td>
                    </tr>
                  )}
                  {OrderDetails?.app_province2 && (
                    <tr>
                      <td>Province / State</td>
                      <td>{OrderDetails?.app_province2}</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>UPLOAD DOCUMENTS</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.itenary_doc_upload && (
                    <tr>
                      <td>Round Trip Flight Ticket</td>
                      <td>
                        <a
                          href={`https://malaysia-evisa.online/uploads/passport/${OrderDetails?.itenary_doc_upload}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.booking_doc && (
                    <tr>
                      <td>Accomodation Docs</td>
                      <td>
                        <a
                          href={`https://malaysia-evisa.online/uploads/passport/${OrderDetails?.booking_doc}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.other_doc && (
                    <tr>
                      <td>Other Docs</td>
                      <td>
                        <a
                          href={`https://malaysia-evisa.online/uploads/passport/${OrderDetails?.other_doc}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}
                  {OrderDetails?.invitation_doc_upload && (
                    <tr>
                      <td>Entry and Exit Stamp</td>
                      <td>
                        <a
                          href={`https://malaysia-evisa.online/uploads/passport/${OrderDetails?.invitation_doc_upload}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                    OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).format("DD-MM-YYYY")}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;
