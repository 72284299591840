import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import ReactDataTable from "../../../components/ReactDataTable";
import { toast } from "react-toastify";

const NewOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const newOrdersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "newOrder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "New Order | Malaysia Admin Portal";
    }, [dispatch, navigate, page, limit]);

    const tableData = newOrdersList;
    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>New Order</h1>
                </StyledPageTitle>
                <form>
                    <StyledOrderBlock>
                        <div className="table-block table-responsive">
                                <ReactDataTable
                                    data={tableData}
                                    setPage={setPage}
                                    setLimit={setLimit}
                                    orderName="newOrder"
                                />
                            
                        </div>
                    </StyledOrderBlock>
                </form>
            </StyledContainer>
        </>
    );
}

export default NewOrders;