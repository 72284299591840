import styled from "styled-components";

export const StyledTrack = styled.div`
  margin-bottom: 457px;
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.3);
    outline: 0;
    box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  }

  @media (max-width: 1366px) {
    margin-bottom: 287px;
  }
  @media (max-width: 991px) {
    margin-bottom: 297px;
  }
  @media (max-width: 767px) {
    margin-bottom: 240px;
  }

  @media (max-width: 560px) {
    margin-bottom: 200px;
  }

  & h3 {
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.13px;
    color: #062f5b;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 15px;
    text-rendering: optimizeLegibility;
  }

  .btn-lg {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    border: none;
  }
  .btn-success {
    color: #fff;
    border-color: #2dbcba;
    text-transform: uppercase;
    background-color: #2dbcba;
    width: 250px;
  }
  .btn&:hover {
    color: var(--bs-btn-hover-color);
    background-color: #062f5b !important;
    border-color: #062f5b;
  }
  .btn-success:hover {
    color: #fff;
    background-color: #062f5b;
    border-color: #062f5b;
  }

  .main-form {
    display: flex;
    gap: 20px;
  }
`;

export const StyledError = styled.p`
  color: red;
`;
