import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader/AdminHeader";
import Main from "./Main";
import { StyledDashboard, StyledMain } from "./style";

const AdminLayout = (props) => {
    return (
        <StyledDashboard>
            <AdminNavbar {...props} />
            <AdminSidebar {...props} />
            <StyledMain>
                <AdminHeader />
                <Main {...props} />
            </StyledMain>
        </StyledDashboard>
    );
}

export default AdminLayout;