import React from "react";
import { StyledNav } from "./style";
import Uklogo from "../../../assets/images/logo.jpg";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNavbar } from "../../../features/order/orderSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const showNavbar = useSelector((state) => state.order.showNavbar);

  return (
    <StyledNav>
      <nav className="bg-light navbar navbar-expand-lg navbar-light">
        <div>
          <div className="navbar-header">
            <Link className="navbar-brand" to="#">
              <img alt="Brand" src={Uklogo} />
            </Link>
          </div>
          <button
            className={
              showNavbar
                ? "navbar-toggler nav-toggler-align"
                : "navbar-toggler collapsed nav-toggler-align"
            }
            // id="navbar-toggle"
            type="button"
            data-bs-toggle={showNavbar ? "collapse show" : "collapse"}
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={showNavbar}
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              onClick={() => dispatch(toggleNavbar())}
            ></span>
          </button>
          <div
            className={
              showNavbar
                ? "collapse navbar-collapse show-nav nav show"
                : "collapse navbar-collapse nav hide"
            }
            id="navbarNav main-nav"
          >
            <ul className="navbar-nav">
              <li
                className={
                  location.pathname === "/" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  aria-current="page"
                  className="nav-link"
                  to="https://malaysia-evisa.online/index.php"
                >
                  Home
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/apply-now"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link
                  className="nav-link"
                  to="https://malaysia-evisa.online/apply.php"
                >
                  Apply Now
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/additional-benefits"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="https://malaysia-evisa.online/benefits.php">
                  Additional Benefits
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/terms-conditions"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="https://malaysia-evisa.online/terms.php">
                  Terms & Conditions
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/privacy-policy"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="https://malaysia-evisa.online/privacy.php">
                  Privacy Policy
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/refund-policy"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="https://malaysia-evisa.online/refund.php">
                  Refund Policy
                </Link>
              </li>

              <li
                className={
                  location.pathname === "/contact-us"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="https://malaysia-evisa.online/contact.php">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </StyledNav>
  );
};

export default Navbar;
